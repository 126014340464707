import { useCallback, useContext, useMemo } from "react";
import { UserContext } from "../context/userContext";
import { hasPermission } from "../helpers/authHelpers";
import type { User } from "../models/user";
import { useCableCallback } from "./cableHooks";

export const useCurrentUser = () => {
  const { currentUser, setUser } = useContext(UserContext);
  const handleUserUpdate = useCallback(
    ({ user }: { user: User }) => {
      if (currentUser?.id === user.id) {
        setUser(user);
      }
    },
    [currentUser?.id, setUser],
  );
  const channel = useMemo(
    () =>
      currentUser?.id
        ? { channel: "UserChannel", id: currentUser.id }
        : undefined,
    [currentUser?.id],
  );
  useCableCallback(channel, handleUserUpdate);
  return currentUser;
};

export const useAuthenticated = () => {
  const authenticated = !!useCurrentUser();
  return authenticated;
};

export const usePermission = (permission: string) => {
  const permissions = usePermissions();
  return hasPermission(permission, permissions);
};

export const useHasPermission = () => {
  const permissions = usePermissions();
  return (permission: string | undefined) =>
    hasPermission(permission, permissions);
};

export const useHasPermissions = () => {
  const userPermissions = usePermissions();
  return (permissions: string[]) =>
    permissions.every((permission) =>
      hasPermission(permission, userPermissions),
    );
};

export const usePermissions = () => {
  return useCurrentUser()?.permissions ?? {};
};
