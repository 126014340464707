import { camelizeKeys, decamelizeKeys } from "humps";
import * as qs from "qs";

interface ApiOptions {
  json?: boolean;
  signal?: AbortSignal;
}

export class ApiBase {
  baseUrl = "/api";
  constructor() {
    if (import.meta.env.DEV) {
      this.baseUrl = import.meta.env.VITE_API_URL;
    }
    const url = localStorage.getItem("baseUrl");
    if (url) {
      this.baseUrl = url;
    }
  }

  options(options?: ApiOptions): ApiOptions {
    const baseOptions = {
      json: true,
    };
    return { ...baseOptions, ...options };
  }

  async get<T, P extends Record<string, unknown> = Record<string, unknown>>(
    url: string,
    params?: P,
    options?: ApiOptions,
  ) {
    const qString = params
      ? qs.stringify(decamelizeKeys(params), {
          addQueryPrefix: true,
          indices: false,
          arrayFormat: "brackets",
        })
      : "";
    const mergedOptions = this.options(options);
    const response = await fetch(`${this.baseUrl}/${url}${qString}`, {
      headers: this.getHeaders(),
      credentials: "include",
    });
    if (response.ok) {
      if (mergedOptions.json) {
        const parsedResponse = await response.json();
        return <T>(<unknown>camelizeKeys(parsedResponse));
      }
      return <T>(<unknown>response);
    }
    const error = await response.text();
    throw error;
  }

  async post<T, P extends Record<string, unknown> = Record<string, unknown>>(
    url: string,
    params?: P,
    options?: ApiOptions,
  ) {
    const mergedOptions = this.options(options);
    const response = await fetch(`${this.baseUrl}/${url}`, {
      method: "POST",
      headers: this.getHeaders(),
      body: params && JSON.stringify(decamelizeKeys(params)),
      signal: options?.signal,
      credentials: "include",
    });
    if (response.ok) {
      if (mergedOptions.json) {
        const parsedResponse = await response.json();
        return <T>(<unknown>camelizeKeys(parsedResponse));
      }
      return <T>(<unknown>response);
    }
    const error = await response.text();
    throw error;
  }

  // tslint:disable-next-line: no-reserved-keywords
  async delete<T, P extends Record<string, unknown> = Record<string, unknown>>(
    url: string,
    params?: P,
  ) {
    const qString = params
      ? qs.stringify(decamelizeKeys(params), {
          addQueryPrefix: true,
          indices: false,
        })
      : "";
    const response = await fetch(`${this.baseUrl}/${url}${qString}`, {
      method: "DELETE",
      headers: this.getHeaders(),
      credentials: "include",
    });
    if (response.ok) {
      const parsedResponse = await response.json();
      return <T>(<unknown>camelizeKeys(parsedResponse));
    }
    const error = await response.text();
    throw error;
  }

  private getHeaders(): HeadersInit {
    const headers: HeadersInit = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    return headers;
  }
}
