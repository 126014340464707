import { use, useEffect, useState } from "react";
import { CableContext } from "../context/cableContext";

export const useCableState = <T>(
  channel: string | undefined,
  initialState: T,
) => {
  const [state, setState] = useState(initialState);
  useCableCallback(channel, setState);
  return state;
};

export const useCableCallback = <T>(
  channel: string | undefined | ActionCable.ChannelNameWithParams,
  callback: (data: T) => void,
) => {
  const cableContext = use(CableContext);

  useEffect(() => {
    if (!channel || !callback || !cableContext) return;

    cableContext.subscribe(channel, callback);
    return () => {
      cableContext.unsubscribe(channel);
    };
  }, [channel, callback, cableContext]);
};
