import * as Sentry from "@sentry/browser";
import {
  type PropsWithChildren,
  createContext,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useLocalStorage } from "usehooks-ts";
import authApi from "../apis/authApi";
import type { User } from "../models/user";

type UserContextType = {
  currentUser: User | undefined;
  validatingToken: boolean;
  logout(): void;
  username: string;
  setUser: (user: User) => void;
};

export const UserContext = createContext<UserContextType>({
  currentUser: undefined,
  validatingToken: false,
  logout: () => {},
  username: "",
  setUser: () => {},
});

export const UserProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [validatingToken, setValidatingToken] = useState(false);
  const [currentUser, setCurrentUser] = useState<User | undefined>();
  const [username, setUsername] = useLocalStorage<string>("username", "");
  const setUser = useCallback(
    (user: User | undefined) => {
      setCurrentUser(user);
      if (user) {
        Sentry.setUser({
          username: user.name,
          id: user.id?.toString(),
        });
        setUsername(user.name);
      } else {
        Sentry.setUser(null);
      }
    },
    [setUsername],
  );
  const logout = useCallback(() => {
    if (currentUser) {
      authApi.logout();
    }
    setUser(undefined);
  }, [setUser, currentUser]);
  const validateToken = useCallback(async () => {
    setValidatingToken(true);
    try {
      const response = await authApi.validateToken();

      if (response.user) {
        setUser(response.user);
      }
    } catch (e) {
      setUser(undefined);
    }

    setValidatingToken(false);
  }, [setUser]);

  useEffect(() => {
    validateToken();
  }, [validateToken]);

  const context = {
    currentUser,
    logout,
    username,
    validatingToken,
    setUser,
  };

  return (
    <UserContext.Provider value={context}>{children}</UserContext.Provider>
  );
};
