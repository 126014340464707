import ActionCable from "actioncable";
import {
  type PropsWithChildren,
  createContext,
  useEffect,
  useRef,
  useState,
} from "react";

type CableContextType = {
  cable: ReturnType<typeof ActionCable.createConsumer>;
  subscribe: <T>(
    channel: string | ActionCable.ChannelNameWithParams,
    callback: (data: T) => void,
  ) => void;
  unsubscribe: (channel: string | ActionCable.ChannelNameWithParams) => void;
};

export const CableContext = createContext<CableContextType | undefined>(
  undefined,
);

export const CableProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [cable, setCable] =
    useState<ReturnType<typeof ActionCable.createConsumer>>();
  const subscriptionsRef = useRef<Map<string, ActionCable.Channel>>(new Map());

  useEffect(() => {
    const newCable = ActionCable.createConsumer(
      `${import.meta.env.VITE_CABLE_URL}`,
    );
    setCable(newCable);
    return () => {
      newCable.disconnect();
    };
  }, []);

  const subscribe = <T,>(
    channel: string | ActionCable.ChannelNameWithParams,
    callback: (data: T) => void,
  ) => {
    if (!cable) return;

    const channelKey =
      typeof channel === "string" ? channel : JSON.stringify(channel);
    if (subscriptionsRef.current.has(channelKey)) return;

    const subscription = cable.subscriptions.create(channel, {
      connected: () => console.log("Connected to cable"),
      received: (data: T) => callback(data),
      disconnected: () => console.log("Disconnected from cable"),
    });

    subscriptionsRef.current.set(channelKey, subscription);
  };

  const unsubscribe = (channel: string | ActionCable.ChannelNameWithParams) => {
    const channelKey =
      typeof channel === "string" ? channel : JSON.stringify(channel);
    const subscription = subscriptionsRef.current.get(channelKey);
    if (subscription) {
      subscription.unsubscribe();
      subscriptionsRef.current.delete(channelKey);
    }
  };

  return (
    <CableContext.Provider value={{ cable: cable!, subscribe, unsubscribe }}>
      {children}
    </CableContext.Provider>
  );
};
